import React from 'react'
import './works.css'
import Lance from '../../images/portfolio/lance.webp'
import Minga from '../../images/portfolio/minga.webp'
import Petshop from '../../images/portfolio/petshop.webp'
import CreatiDesign from '../../images/portfolio/creatidesign.webp'
import { useEffect } from 'react'
import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

export default function Works() {
    useEffect(() => {
        const containerBricks = document.querySelector('.masonry');
    
        const initializeMasonry = () => {
          new Masonry(containerBricks, {
            itemSelector: '.masonry__brick',
            resize: true,
          });
        };
    
        // Utiliza la biblioteca 'imagesloaded' para esperar a que las imágenes se carguen
        imagesLoaded(containerBricks, initializeMasonry);
      }, []);

    return (
        <section id="trabajos" className="s-works target-section">

            <div className="row section-header has-bottom-sep narrow target-section" data-aos="fade-up" data-aos-delay="500">
                <div className="col-full">
                    <h2 className="subhead">Nuestros Trabajos</h2>
                    <h3 className="display-1">
                        Creamos marcas, productos y experiencias que enamoran a las personas. Echa un vistazo a nuestros
                        proyectos recientes.
                    </h3>
                </div>
            </div>

            <div className="row masonry-wrap">

                <div className="masonry">

                    <section className="masonry__brick" data-aos="fade-up" data-aos-delay="500">
                        <div className="item-folio">

                            <div className="item-folio__thumb">
                                <span className="thumb-link" title="CreatiDesign" data-size="1050x700">
                                    <img src={CreatiDesign} alt="Proyecto" width="auto" height="auto"/>
                                </span>
                            </div>

                            <div className="item-folio__text">
                                <h3 className="item-folio__title">
                                    CreatiDesign
                                </h3>
                                <p className="item-folio__cat">
                                    Desarrollo Web
                                </p>
                                <p className="item-folio__desc">Sitio web que da a conocer una companía de diseño gráfico.</p>
                            </div>

                            <a href="https://creatidesign.vercel.app/" className="item-folio__project-link" title="Project link"
                                target="_blank" rel="noreferrer">
                                <i className="icon-link"></i>
                            </a>

                        </div>
                    </section>

                    <section className="masonry__brick" data-aos="fade-up" data-aos-delay="500">
                        <div className="item-folio">

                            <div className="item-folio__thumb">
                                <span className="thumb-link" title="Lance" data-size="1050x700">
                                    <img src={Lance} alt="Proyecto" />
                                </span>
                            </div>

                            <div className="item-folio__text">
                                <h3 className="item-folio__title">
                                    Lance
                                </h3>
                                <p className="item-folio__cat">
                                    Desarrollo Web
                                </p>
                                <p className="item-folio__desc">Sitio web en el que cada usuario puede crear una tienda en
                                    línea.</p>
                            </div>

                            <a href="https://lance-app.vercel.app/" className="item-folio__project-link" title="Project link"
                                target="_blank" rel="noreferrer">
                                <i className="icon-link"></i>
                            </a>

                        </div>
                    </section>

                    <section className="masonry__brick" data-aos="fade-up" data-aos-delay="500">
                        <div className="item-folio">

                            <div className="item-folio__thumb">
                                <span className="thumb-link" title="Minga" data-size="1050x700">
                                    <img src={Minga} alt="Proyecto" />
                                </span>
                            </div>

                            <div className="item-folio__text">
                                <h3 className="item-folio__title">
                                    Minga
                                </h3>
                                <p className="item-folio__cat">
                                    Desarrollo Web
                                </p>
                                <p className="item-folio__desc">Sitio web en el que se pueden leer cómics/mangas.</p>
                            </div>

                            <a href="https://jr-minga-front.vercel.app/" target="_blank" rel="noreferrer" className="item-folio__project-link"
                                title="Project link">
                                <i className="icon-link"></i>
                            </a>

                        </div>
                    </section>

                    <section className="masonry__brick" data-aos="fade-up" data-aos-delay="500">
                        <div className="item-folio">

                            <div className="item-folio__thumb">
                                <span className="thumb-link" title="Petshop" data-size="1050x700">
                                    <img src={Petshop} alt="Proyecto" />
                                </span>
                            </div>

                            <div className="item-folio__text">
                                <h3 className="item-folio__title">
                                    Petshop
                                </h3>
                                <p className="item-folio__cat">
                                    Desarrollo Web
                                </p>
                                <p className="item-folio__desc">Tienda en línea para comprar productos de juguetería y farmacia
                                    canina</p>
                            </div>

                            <a href="https://julianramos42.github.io/petshop/" target="_blank" rel="noreferrer"
                                className="item-folio__project-link" title="Project link">
                                <i className="icon-link"></i>
                            </a>

                        </div>
                    </section>

                </div>

            </div>

        </section>
    )
}
