import './App.css';
import './micons/micons.css' // Configuration of icons
import './vendor.css'
import Header from './components/Header/Header'
import Home from './components/Home/Home'
import About from './components/About/About';
import About2 from './components/About2/About2'; // ABOUT2 ==== WHAT WE DO
import Works from './components/Works/Works';
import Prices from './components/Prices/Prices';
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
// import { useRef } from 'react';
import { useEffect } from 'react';
import AOS from 'aos';
// import 'aos/dist/aos.css';

function App() {
  // let preloader = useRef()
  // let loader = useRef()

  // function deactivatePreloader() {
  //   setTimeout(() => {
  //     loader.current.id = ""
  //   }, 800)
  //   setTimeout(() => {
  //     preloader.current.hidden = true
  //   }, 1000)
  // }

  useEffect(() => {
    // deactivatePreloader()
    AOS.init({
      duration: 600,
      once: true
    });
  }, [])

  return (
    <div className='page-container'>
      {/* <div ref={preloader} id="preloader">
        <div ref={loader} id="loader" className="dots-jump">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> */}

      <Header />
      <main>
        <Home />
        <About />
        <About2 />
        <Works />
        <Prices />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
