import React from 'react'
import './contact.css'

export default function Contact() {
    return (
        <section id="contacto" className="s-contact target-section">

            <div className="grid-overlay">
                <div></div>
            </div>

            <div className="row section-header narrow" data-aos="fade-up" data-aos-delay="500">
                <div className="col-full">
                    <h3 className="subhead">Mantenete en contacto</h3>
                    <p className="display-1">No dudes en ponerte en contacto con nosotros para cualquier<br /> idea de proyecto o
                        colaboración.</p>
                </div>
            </div>

            <div className="row contact-main" data-aos="fade-up" data-aos-delay="500">
                <div className="col-full">
                    <p className="contact-email">
                        <a href="mailto:tuwebhoy1@gmail.com">tuwebhoy1@gmail.com</a>
                    </p>
                    <p className="contact-address">
                        Buenos Aires, Argentina <br />
                    </p>

                    <ul className="contact-social">
                        <li>
                            <a href="https://www.facebook.com/tuwebhoy.online" target="_blank" rel='noreferrer'><i
                                className="fab fa-facebook"></i></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/tuwebhoy.online/" target="_blank" rel='noreferrer'><i
                                className="fab fa-instagram"></i></a>
                        </li>
                        {/* <li>
                        <a href="#0"><i className="fab fa-tiktok"></i></a>
                    </li> */}
                        <li>
                            <a href="https://wa.me/5491150234561?text=Hola, me gustaría desarrollar mi sitio web con vos. ¿Podrías darme mas información?"
                                target="_blank" rel='noreferrer'><i className="fab fa-whatsapp"></i></a>
                        </li>
                    </ul>
                </div>
            </div>

        </section>
    )
}
