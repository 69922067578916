import React from 'react'
import './header.css'
import Logo from '../../images/logo.webp'
import { useRef, useState, useEffect } from 'react'

export default function Header() {
  let menu = useRef()
  let [menuOpen, setMenuOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false);
  
  document.body.classList.toggle('menuOpen', menuOpen) // si el menu se activa, la scrollbar se esconde

  function toggleBtn(){ // abre y cierra el menu
    menu.current.classList.toggle('menu-is-open')
    setMenuOpen(!menuOpen)
  }

  // CONTROL THE SCROLL MENU

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // SMOOTHSCROLL

  useEffect(() => {
    const smoothScrollLinks = document.querySelectorAll('.smoothscroll');

    smoothScrollLinks.forEach(link => {
      link.addEventListener('click', (event) => {
        event.preventDefault(); // Evita la navegación predeterminada

        const targetId = link.getAttribute('href').substring(1); // Elimina el "#" del href
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });
  }, []);

  return (
    <header ref={menu} className="s-header">

      <div className="header-logo">
          <img src={Logo} alt="Homepage" />
      </div>

      <nav className="header-nav">

        <button className="header-nav__close" title="Cerrar" onClick={toggleBtn}><span>Close</span></button>

        <h3>Navegar a</h3>

        <div className="header-nav__content">

          <ul className="header-nav__list">
            <li><a className="smoothscroll" onClick={toggleBtn} href="#inicio" title="Inicio">Inicio</a></li>
            <li><a className="smoothscroll" onClick={toggleBtn} href="#acerca-de" title="Acerca De">Acerca De</a></li>
            <li><a className="smoothscroll" onClick={toggleBtn} href="#lo-que-hacemos" title="Lo Que Hacemos">Lo Que Hacemos</a></li>
            <li><a className="smoothscroll" onClick={toggleBtn} href="#trabajos" title="Trabajos">Trabajos</a></li>
            <li><a className="smoothscroll" onClick={toggleBtn} href="#precios" title="Precios">Precios</a></li>
            <li><a className="smoothscroll" onClick={toggleBtn} href="#contacto" title="Contacto">Contacto</a></li>
          </ul>

          <ul className="header-nav__social">
            <li>
              <a href="https://www.facebook.com/tuwebhoy.online" target="_blank" rel='noreferrer'><i
                className="fab fa-facebook"></i></a>
            </li>
            <li>
              <a href="https://www.instagram.com/tuwebhoy.online/" target="_blank" rel='noreferrer'><i
                className="fab fa-instagram"></i></a>
            </li>
            {/* <li>
                        <a href=""><i className="fab fa-tiktok"></i></a>
                    </li>  */}
            <li>
              <a href="https://wa.me/5491150234561?text=Hola, me gustaría desarrollar mi sitio web con vos. ¿Podrías darme mas información?"
                target="_blank" rel='noreferrer'><i className="fab fa-whatsapp"></i></a>
            </li>
          </ul>

        </div>

      </nav>

      <button className={scrolled ? "header-menu-toggle opaque" : "header-menu-toggle"} onClick={toggleBtn} aria-label='abrir-menu' role="menubar">
        <span className="header-menu-icon"></span>
      </button>

    </header>
  )
}