import React from 'react'
import './footer.css'
import { useEffect } from 'react';

export default function Footer() {

  useEffect(() => {
    const smoothScrollLinks = document.querySelectorAll('.smoothscroll');

    smoothScrollLinks.forEach(link => {
      link.addEventListener('click', (event) => {
        event.preventDefault(); // Evita la navegación predeterminada

        const targetId = link.getAttribute('href').substring(1); // Elimina el "#" del href
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });
  }, []);

  return (
    <footer>
        <div className="row">
            <div className="col-full ss-copyright">
                <span>© Copyright TuWebHoy 2023</span>
                <span>Diseño: <a href="https://www.styleshout.com/" target="_blank" rel='noreferrer'>Styleshout</a></span>
            </div>
        </div>

        <div className="ss-go-top">
            <a className="smoothscroll" title="Back to Top" href="#inicio">Back to Top</a>
        </div>
    </footer>
  )
}
