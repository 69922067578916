import React from 'react'
import './prices.css'
import Check from '../../images/tic.webp'

export default function Prices() {
  return (
    <section id="precios" className="s-clients target-section darker">

        <div className="grid-overlay">
            <div></div>
        </div>

        <div className="row section-header text-center narrow" data-aos="fade-up" data-aos-delay="500">
            <div className="col-full">
                <h2 className="subhead">Precios</h2>
                <p className=" h2 display-1">Elegí entre estos planes</p>
            </div>
        </div>

        <div className="row clients-wrap" data-aos="fade-up" data-aos-delay="500">
            <div className="col-twelve prices">
                <section className="prices-card">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">Landing Page</h2>
                            <p className="card-text">Ideal para campañas publicitarias</p>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item sinborde">
                                <span className="signo">$</span><span className="precio">80.000</span>
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item">
                                <img src={Check} alt="check" className="check" /> Sitio creado en HTML, CSS y JS<br/>
                                <img src={Check} alt="check" className="check" /> 1 sección precargada<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 10 fotos precargadas
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item">
                                <img src={Check} alt="check" className="check" /> Optimización para todo tipo de dispositivo<br/>
                                <img src={Check} alt="check" className="check" /> 1 formulario de contacto<br/>
                                <img src={Check} alt="check" className="check" /> Links a redes sociales<br/>
                                <img src={Check} alt="check" className="check" /> Botón de WhatsApp<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 5 cuentas de mail
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item borderbajo">
                                <img src={Check} alt="check" className="check" /> Hosting x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> Dominio .com.ar x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> Certificado SSL x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> No necesita mantenimiento web
                            </li>
                        </ul>
                        <hr className="bar"/>
                        <div className="card-footer"><a target="_blank" rel="noreferrer"
                                href="https://wa.me/5491150234561?text=Hola, estoy interesado en desarrollar una Landing Page."><button
                                    className="btn masinfo btn-prices">Contratar Landing Page</button></a></div>
                    </div>
                </section>

                <section className="prices-card">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">Web Corporativa</h2>
                            <p className="card-text">La mejor opción para su empresa</p>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item sinborde">
                                <span className="signo">$</span><span className="precio">100.000</span>
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item">
                                <img src={Check} alt="check" className="check" /> Sitio creado WordPress<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 6 secciones precargadas<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 15 fotos precargadas
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item">
                                <img src={Check} alt="check" className="check" /> Optimización para todo tipo de dispositivo<br/>
                                <img src={Check} alt="check" className="check" /> 1 formulario de contacto<br/>
                                <img src={Check} alt="check" className="check" /> Links a redes sociales<br/>
                                <img src={Check} alt="check" className="check" /> Botón de WhatsApp<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 5 cuentas de mail
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item borderbajo">
                                <img src={Check} alt="check" className="check" /> Hosting x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> Dominio .com.ar x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> Certificado SSL x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> Mantenimiento web x 3 meses
                            </li>
                        </ul>
                        <hr className="bar"/>
                        <div className="card-footer"><a target="_blank" rel="noreferrer"
                                href="https://wa.me/5491150234561?text=Hola, estoy interesado en desarrollar una Web Corporativa."><button
                                    className="btn masinfo btn-prices">Contratar Web Corporativa</button></a></div>
                    </div>
                </section>

                <section className="prices-card">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">Tienda Online</h2>
                            <p className="card-text">Vende tus productos en línea</p>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item sinborde">
                                <span className="signo">$</span><span className="precio">125.000</span>
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item">
                                <img src={Check} alt="check" className="check" /> Sitio creado en Wordpress y Woocommerce<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 6 secciones precargadas<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 15 fotos precargadas<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 5 categorías precargadas<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 10 productos precargados
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item">
                                <img src={Check} alt="check" className="check" /> Optimización para todo tipo de dispositivo<br/>
                                <img src={Check} alt="check" className="check" /> 1 formulario de contacto<br/>
                                <img src={Check} alt="check" className="check" /> Links a redes sociales<br/>
                                <img src={Check} alt="check" className="check" /> Botón de WhatsApp<br/>
                                <img src={Check} alt="check" className="check" /> Hasta 5 cuentas de mail
                            </li>
                            <hr className="bar"/>
                            <li className="list-group-item borderbajo">
                                <img src={Check} alt="check" className="check" /> Hosting x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> Dominio .com.ar x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> Certificado SSL x 1 año<br/>
                                <img src={Check} alt="check" className="check" /> Mantenimiento web x 3 meses
                            </li>
                        </ul>
                        <hr className="bar"/>
                        <div className="card-footer"><a target="_blank" rel='noreferrer'
                                href="https://wa.me/5491150234561?text=Hola, estoy interesado en desarrollar una Tienda Online."><button
                                    className="btn masinfo btn-prices">Contratar Tienda Online</button></a></div>
                    </div>
                </section>
            </div>
        </div>

    </section>
  )
}
