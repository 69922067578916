import React from 'react'
import './about.css'

export default function About() {
    return (
        <section id="acerca-de" className="s-about target-section">

            <div className="row section-header bit-narrow" data-aos="fade-up" data-aos-delay="500">
                <div className="col-full">
                    <h2 className="subhead">¿Quienes Somos?</h2>
                    <h3 className="display-1">
                        Somos TuWebHoy, una agencia de desarrollo web comprometida en brindar experiencias digitales
                        impactantes y estéticas.
                        Trabajamos con vos para crear tu sitio web esperado.
                    </h3>
                </div>
            </div>

            <div className="row bit-narrow" data-aos="fade-up" data-aos-delay="500">
                <div className="col-full">
                    <p className="lead">
                        Nuestra pasión radica en llevar tus ideas y visiones en línea de manera excepcional. Somos una
                        agencia de desarrollo web dedicada a convertir tu visión en una realidad digital cautivadora.
                        Trabajamos codo a codo contigo para comprender tus necesidades y metas, diseñando y desarrollando
                        sitios web personalizados que no solo destacan en estética, sino que también brindan experiencias
                        digitales impactantes. Nuestra promesa es convertir tu sitio web de ensueño en una realidad hoy
                        mismo, poniendo tus ideas al alcance de tus clientes y seguidores de inmediato.
                    </p>
                </div>
            </div>

            <div className="row bit-narrow">

                <div className="about-process process block-1-2 block-tab-full">

                    <div className="col-block item-process" data-aos="fade-up" data-aos-delay="500">
                        <div className="item-process__text">
                            <h4 className="item-title">Definir</h4>
                            <p>
                                En esta etapa inicial, trabajamos estrechamente contigo para comprender a fondo tus
                                objetivos, necesidades y visión. Nos sumergimos en tu proyecto, explorando tus ideas y
                                expectativas para definir claramente el camino hacia el sitio web de tus sueños. Juntos,
                                establecemos los cimientos sólidos que guiarán todo el proceso de desarrollo.
                            </p>
                        </div>
                    </div>
                    <div className="col-block item-process" data-aos="fade-up" data-aos-delay="500">
                        <div className="item-process__text">
                            <h4 className="item-title">Diseñar</h4>
                            <p>
                                Una vez que hemos definido tus objetivos y requerimientos, nuestro talentoso equipo de
                                diseñadores da vida a tus ideas. Creamos una identidad visual única que refleja tu visión y
                                valores, diseñando cuidadosamente cada aspecto de tu sitio web. Durante esta fase, te
                                presentamos propuestas creativas y colaboramos contigo para asegurarnos de que el diseño sea
                                un reflejo auténtico de tu marca. Juntos, transformamos conceptos en una estética web
                                cautivadora y funcional.
                            </p>
                        </div>
                    </div>
                    <div className="col-block item-process" data-aos="fade-up" data-aos-delay="500">
                        <div className="item-process__text">
                            <h4 className="item-title">Construir</h4>
                            <p>
                                Una vez que hemos pulido el diseño y todos los detalles están en su lugar, es hora de dar
                                vida a tu sitio web. Nuestro equipo de desarrollo se pone manos a la obra para construir una
                                plataforma sólida y funcional que cumpla con tus especificaciones. Cada línea de código se
                                escribe con precisión, cada elemento se integra con perfección, y cada característica se
                                pone en marcha. Trabajamos incansablemente para garantizar que tu sitio web sea seguro,
                                eficiente y de alto rendimiento. Durante esta fase, mantenemos una comunicación constante
                                contigo para que estés al tanto de cada avance.
                            </p>
                        </div>
                    </div>
                    <div className="col-block item-process" data-aos="fade-up" data-aos-delay="500">
                        <div className="item-process__text">
                            <h4 className="item-title">Lanzar</h4>
                            <p>
                                Llegado el momento, tu sitio web está listo para su gran debut. Nos aseguramos de que todo
                                esté en su lugar y funcionando a la perfección antes de lanzar oficialmente tu proyecto.
                                Realizamos pruebas exhaustivas para garantizar que cada elemento esté en su sitio y que la
                                experiencia del usuario sea impecable. Una vez que estemos seguros de que todo está en
                                orden, procedemos al emocionante momento de lanzar tu sitio web al mundo. Nuestro compromiso
                                no termina aquí; seguimos monitoreando y brindando soporte para garantizar que tu presencia
                                en línea sea exitosa a largo plazo.
                            </p>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    )
}
