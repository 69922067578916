import React from 'react'
import './home.css'

export default function Home() {

  return (
    <section id="inicio" className="s-home page-hero target-section">

      <div className="grid-overlay">
        <div></div>
      </div>

      <div className="home-content">

        <div className="row home-content__main" data-aos="fade-up" data-aos-delay="100">

          <h1>TuWebHoy<span className="h3">- Creamos un sitio personalizado para impulsar tu negocio -</span></h1>

          <div className="home-content__video">
            {/* <a className="video-link"
              href="https://player.vimeo.com/video/117310401?color=01aef0&title=0&byline=0&portrait=0"
              data-lity>
              <span className="video-icon"></span>
              <span className="video-text">Ver Video</span>
            </a> */}
          </div>

          <div className="home-content__button">
            <a href="#acerca-de" className="smoothscroll btn btn--primary btn--large">
              Más sobre nosotros
            </a>
            <a href="#contacto" className="smoothscroll btn btn--large">
              Contáctanos
            </a>
          </div>

        </div>

        <div className="home-content__scroll">
          <a href="#about" className="scroll-link smoothscroll">
            Scroll
          </a>
        </div>

      </div>

      <ul className="home-social">
        <li>
          <a href="https://www.facebook.com/tuwebhoy.online" target="_blank" rel='noreferrer'><i className="fab fa-facebook-f"
            aria-hidden="true"></i><span>Facebook</span></a>
        </li>
        <li>
          <a href="https://www.instagram.com/tuwebhoy.online/" target="_blank" rel='noreferrer'><i className="fab fa-instagram"
            aria-hidden="true"></i><span>Instagram</span></a>
        </li>
        {/*  <li>
                <a href=""><i className="fab fa-tiktok" aria-hidden="true"></i><span>Tiktok</span></a>
            </li>  */}
        <li>
          <a href="https://wa.me/5491150234561?text=Hola, me gustaría desarrollar mi sitio web con vos. ¿Podrías darme mas información?"
            target="_blank" rel='noreferrer'><i className="fab fa-whatsapp" aria-hidden="true"></i><span>Whatsapp</span></a>
        </li>
      </ul>

    </section >
  )
}